<template>

  <!-- Sections Directory View -->
  <j-page
    title="Sections"
    :loading="loading"
    fill
    id="sections-directory-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Search Bar -->
      <j-search
        @search="handleSearch"
        @clear="handleClear"
      />

    </template>

    <!-- Left - Magazine and News -->
    <template #left>

      <!-- Magazine -->
      <SectionTable 
        heading="Magazine"
        :sections="magazine"
        :filter="filter"
      />

      <!-- News -->
      <SectionTable 
        heading="News"
        :sections="news"
        :filter="filter"
      />

    </template>

    <!-- Right - Columns -->
    <template #right>

      <!-- Columns -->
      <SectionTable
        heading="Columns"
        :sections="columns"
        :filter="filter"
      />

    </template>
  </j-page>
</template>

<script>

// Components
import SectionTable from '@/components/widgets/sections/SectionTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('sections')

export default {
  name: 'SectionsDirectoryView',
  // -------------
  // Components ==
  // -------------
  components: {
    SectionTable
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.fetchSections()
    })
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Sections'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'magazine',
      'news',
      'columns'
    ]),
    ...mapGetters([
      'loading'
    ])
  },
  data () {
    return {
      filter: ''
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchSections'
    ]),
    handleSearch(filter) {
      this.filter = filter
    },
    handleClear () {
      this.filter = ''
    }
  }
}
</script>
