<template>
  <j-table
    class="section-table"
    :listing="sections"
    items="sections"
    :heading="heading"
    :subhead="subhead"
  >
    <!-- Header -->
    <template #header>
      <tr>
        <th width="25%">
          Section
        </th>
        <th width="25%">
          Colors
        </th>
        <th width="35%">
          Latest
        </th>
        <th width="15%">
          &nbsp;
        </th>
      </tr>
    </template>

    <!-- Listing -->
    <template #listing>
      <tr
        v-for="section in filteredSections"
        :key="section.slug"
      >
        <!-- Name -->
        <td>
          <router-link :to="{ name: 'sections-view', params: { slug: section.slug }}">
            <strong>{{ section.name | truncate }}</strong>
          </router-link>
        </td>

        <!-- Colors -->
        <td>
          <v-chip
            v-if="section.highlight_color"
            class="ma-1 font-weight-bold"
            :color="section.highlight_color"
            text-color="white"
            small
            label
          >
            <span :title="section.highlight_color">HL</span>
          </v-chip>

          <v-chip
            v-if="section.background_color"
            class="ma-1 font-weight-bold"
            :color="section.background_color"
            small
            label
          >
            <span :title="section.background_color">BG</span>
          </v-chip>
        </td>

        <!-- Latest Item Title -->
        <td> 
          <router-link
            v-if="section.latest_item.title"
            :to="{ name: 'articles-view', params: { tag19: section.latest_item.tag19 }}"
          >
            {{ section.latest_item.title | truncate(40) }}
          </router-link>
        </td>

        <!-- Latest Item Publication Date -->
        <td> {{ section.latest_item.published_at | shortDate }} </td>
      </tr>
    </template>
  </j-table>
</template>

<script>

export default {
  name: 'SectionTable',
  // -------------
  // Properties ==
  // -------------
  props: {
    sections: {
      type: Object,
      default: null
    },
    heading: {
      type: String,
      default: ''
    },
    subhead: {
      type: String,
      default: ''
    },
    filter: {
      type: String,
      default: ''
    }
  },
  computed: {
    filteredSections() {
      if (this.filter) {
        return this.sections.getModels().filter((section) => section.get('name').toLowerCase().includes(this.filter.toLowerCase()))
      } else {
        return this.sections.getModels()
      }
    }
  }
}
</script>
